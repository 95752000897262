import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContentProps, toast } from "react-toastify";

import { QoplaPromotionToastProps, QoplaPromotionType } from "Types";
import { isLocalDeveloper } from "Utils";
import { useLanguage } from "LanguageProvider";
import { Box, Button, Flex, Header, RHFormInput, Text } from "Atoms";
import { useMothershipMutation } from "Hooks";
import { SAVE_PROMOTION_CONTACT_INFORMATION } from "GraphQLMutations";

type ToastProps = Partial<ToastContentProps> & QoplaPromotionToastProps;

type ToastContactData = {
    name: string;
    email: string;
    phoneNumber: string;
};

type QoplaPromotionHookReturn = {
    onHandlePromotionToast: (promotionToast: QoplaPromotionToastProps) => void;
};

/**
 * [FUNCTION] - Adding line break to the promotion text
 * @description - Line breaks to create space between sentences (based on special characters)
 * @param text
 * @returns
 */
const AddLineBreaks = (text: string) => {
    const addLineBreaks = (text: string) => {
        const parts = text.split(/([!?\.])\s*/g).filter(Boolean);

        return parts.map((part, index) => {
            const specialChars = /[!?\.]/.test(part);
            const isLastIndex = index === parts.length - 1;
            return (
                <React.Fragment key={index}>
                    <Box as="span">{part}</Box>
                    {specialChars && !isLastIndex && (
                        <>
                            <Box as="br" />
                            <Box as="br" />
                        </>
                    )}
                </React.Fragment>
            );
        });
    };

    return (
        <Text fontSize={"lg"} whiteSpace={"pre-line"}>
            {addLineBreaks(text)}
        </Text>
    );
};

export const useQoplaPromotionToast = (): QoplaPromotionHookReturn => {
    /**
     * [FUNCTION] - Allow simple custom toast
     * @param qoplaPromotion
     * @param preview
     */
    const onShowSimpleNotification = (qoplaPromotion: QoplaPromotionType) => {
        toast(<QoplaPromotionInformation promotion={qoplaPromotion} />, {
            theme: "dark",
            toastId: qoplaPromotion.id
        });
    };

    /**
     * [FUNCTION] - Allow contact information toast
     * @param promotionToast
     */
    const onShowContactInformationNotification = (promotionToast: QoplaPromotionToastProps) => {
        toast(
            (toastProps: ToastContentProps) => <PromotionContactInformationForm {...toastProps} {...promotionToast} />,
            {
                closeOnClick: false,
                autoClose: false,
                draggable: false,
                theme: "dark",
                toastId: promotionToast.qoplaPromotion.id
            }
        );
    };

    const onHandlePromotionToast = (promotionToast: QoplaPromotionToastProps) => {
        if (!!promotionToast.qoplaPromotion?.collectContactInformation) {
            onShowContactInformationNotification(promotionToast);
        } else {
            onShowSimpleNotification(promotionToast.qoplaPromotion);
        }
    };

    return {
        onHandlePromotionToast
    };
};

/**
 * [COMPONENT] - Qopla Promotion Information
 * @description - Display the simple information of the promotion which appears in the toast
 * @param param0
 * @returns
 */
const QoplaPromotionInformation: React.FC<{ promotion: QoplaPromotionType }> = ({ promotion }) => {
    return (
        <Flex direction={"column"} width={"100%"} p={2} pl={4}>
            <Header size="md">{promotion.name}</Header>
            {AddLineBreaks(promotion.promotionMessage)}
        </Flex>
    );
};

/**
 * [COMPONENT] - Promotion Contact Information Form
 * @description - Display the form to collect the contact information of the user
 * @param param0
 * @returns
 */
const PromotionContactInformationForm = ({ toastProps, ...props }: ToastProps) => {
    const { qoplaPromotion, preview, authenicatedUser, companyId, companyName, preSelectedContactInfo } = props;
    const { translate } = useLanguage();

    const [savePromotionContact] = useMothershipMutation(SAVE_PROMOTION_CONTACT_INFORMATION);

    const contactInformationSchema = Yup.object().shape({
        name: Yup.string().required(translate("mustFillIn")),
        email: Yup.string().email().required(translate("mustFillIn")),
        phoneNumber: Yup.string().required(translate("mustFillIn"))
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = useForm<ToastContactData>({
        mode: "onBlur",
        defaultValues: {
            name: preSelectedContactInfo?.name || "",
            email: preSelectedContactInfo?.email || "",
            phoneNumber: preSelectedContactInfo?.phoneNumber || ""
        },
        resolver: yupResolver(contactInformationSchema)
    });

    const onSubmit: SubmitHandler<ToastContactData> = async (
        data: ToastContactData,
        event?: React.BaseSyntheticEvent
    ) => {
        event?.preventDefault();
        const promotionContactLead = {
            qoplaPromotionId: qoplaPromotion.id,
            companyId: !!companyId ? companyId : authenicatedUser?.companyId,
            companyName: !!companyName ? companyName : "",
            ...data
        };

        if (preview?.allowSubmit && isLocalDeveloper(authenicatedUser.roles)) {
            Reflect.set(promotionContactLead, "companyName", "[TEST_COMPANY]");
        }

        const response = await savePromotionContact({
            variables: {
                promotionContactLead: promotionContactLead
            }
        });

        if (!!response.data?.savePromotionContactInformation) {
            toast.success(() => <>{translate("infoHasBeenSent")}</>, {
                type: "success",
                theme: "dark",
                autoClose: 3000
            });
        } else {
            toast.error(() => <>{translate("somethingWentWrong")}</>, {
                type: "error",
                theme: "dark",
                autoClose: 3000
            });
        }
        toastProps?.closeToast();
    };

    return (
        <Flex direction={"column"} width={"100%"} height={"max-content"}>
            <QoplaPromotionInformation promotion={qoplaPromotion} />
            <Flex p={2} pl={4} mt={2} direction={"column"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <RHFormInput
                        control={control}
                        name="name"
                        color={"gray.900"}
                        formLabel={translate("name")}
                        placeholder={translate("name")}
                    />
                    <RHFormInput
                        control={control}
                        name="email"
                        color={"gray.900"}
                        formLabel={translate("email")}
                        placeholder={translate("email")}
                    />
                    <RHFormInput
                        control={control}
                        name="phoneNumber"
                        color={"gray.900"}
                        formLabel={translate("phoneNumber")}
                        placeholder={translate("phoneNumber")}
                        type="tel"
                        onInput={(e: React.FormEvent<HTMLInputElement>) =>
                            (e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g, ""))
                        }
                    />
                    <Button mt={4} themeColor="green" fullWidth type="submit" disabled={!isValid || isSubmitting}>
                        {qoplaPromotion.contactButtonText}
                    </Button>
                </form>
            </Flex>
        </Flex>
    );
};
